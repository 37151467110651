import React from "react";
import I18n from '../../config/I18n';

import "./CardService.scss";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";


import {
  faPaperPlane,
  faDesktop,
  faMobileAlt
} from "@fortawesome/free-solid-svg-icons";

library.add(fab, faPaperPlane, faDesktop, faMobileAlt);

function CardService({
  service: { title, description, className, fontAwesomeIcon }
}) {
  return (
    <React.Fragment>
      <div className="service text-center">
        <span className={`icon ${className} mb-4 d-block`}>
          <FontAwesomeIcon icon={fontAwesomeIcon} />
        </span>
        <h3><I18n t={`services.${title}`} /></h3>
        <p><I18n t={`services.${description}`} /></p>
      </div>
    </React.Fragment>
  );
}

export default CardService;
