import React from "react";

// import { Link } from "react-router-dom";
import SwitchLanguage from "../SwitchLanguage/SwitchLanguage"

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
// import { faTwitter } from "@fortawesome/free-solid-svg-icons";
import {
  faEdit,
  faTrashAlt,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import "./Footer.scss";

// library.add(fab, faTwitter);
library.add(fab, faEdit, faTrashAlt, faSearch);

function Footer() {
  const socialMedias = [
    { url: "https://twitter.com/HumanToDev", icon: ["fab", "twitter"] },
    { url: "https://www.facebook.com/humantodev", icon: ["fab", "facebook-f"] },
    {
      url: "https://www.linkedin.com/company/humantodev",
      icon: ["fab", "linkedin"]
    },
    { url: "https://www.instagram.com/HumanToDev/", icon: ["fab", "instagram"] }
  ];

  const getFullYear = new Date().getFullYear();
  const copyrightMessage = `Copyright © ${getFullYear} Todos los derechos reservados`;

  return (
    <React.Fragment>
      <footer className="footer py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="list-unstyled d-flex footer-social justify-content-center m-0">
                {socialMedias.map((socialMedia, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={socialMedia.url}
                        target="blank"
                        className="footer-link"
                      >
                        <FontAwesomeIcon icon={socialMedia.icon} />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-md-center text-left">
              <p className="footer-copyright mt-2 mb-0">{copyrightMessage}</p>
            </div>
          </div>
          < SwitchLanguage />
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
