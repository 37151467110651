import React from "react";

import I18n from "../../config/I18n";

import "./CardMethodology.scss";

function CardMethodology({ methodology: { title, body, img } }) {
  return (
    <div className="col-sm-4 col-md-4 col-lg-4">
      <div className="foodWrapper">
        <div className="image-hover-image">
          <img
            src={require(`../../images/${img}`)}
            alt="Metohology"
            className="img-fluid"
          />
        </div>
        <div className="image-hover-text">
          <div className="image-hover-text-bubble">
            <span className="image-hover-text-title">
              <I18n t={`methologies.${title}`} />
            </span>
            <I18n t={`methologies.${body}`} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardMethodology;
