import React from 'react';
import { NavLink } from 'react-router-i18n';
import { useLocation } from 'react-router-dom';
import I18n from '../../config/I18n';

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

import "./SwitchLanguage.scss";

import {
  faLanguage
} from "@fortawesome/free-solid-svg-icons";

library.add(fab, faLanguage);

const Hello = () => {
  const  {pathname} = useLocation();
  return(
  <div className="switch">
    <p>
      <FontAwesomeIcon icon='language' />
    </p>
      <NavLink
        ignoreLocale
        to='/es'
        className="nav__link"
        activeClassName="active">
        <div> <I18n t='spanish' /> </div>
      </NavLink>

      <NavLink
        ignoreLocale
        to='/en'
        className="nav__link"
        activeClassName="active"
        isActive={() => (!!(pathname ===  '/en' || pathname === '/'))}>
        <div> <I18n t='english' /> </div>
      </NavLink>
  </div>
)}

export default Hello;
