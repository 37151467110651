import React, { useState } from "react";

import CardTestimony from "../CardTestimony/CardTestimony";
import Carousel from "react-bootstrap/Carousel";
import dataTestimonials from "./testimonials";

import "./SectionTestimonials.scss";

function SectionTestimonials() {
  const testimonials = dataTestimonials.testimonials;
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };

  return (
    <React.Fragment>
      <Carousel
        activeIndex={index}
        direction={direction}
        onSelect={handleSelect}
      >
        {testimonials.map((element, index) => {
          return (
            <Carousel.Item key={index}>
              <CardTestimony testimony={element} key={index} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </React.Fragment>
  );
}

export default SectionTestimonials;
