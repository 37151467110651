const en = {
  language: 'Language: ',
  spanish: 'Spanish',
  english: 'English',
  sections: {
    banner: "We're HumanToDev",
    banner_sub: 'We accompany you to present your idea technologically ... your business.',
    methology: 'Our Methodology',
    service: 'What do we do for you?',
    testimony: 'What do our customers say?',
    contact: 'Contact us'
  },
  about_us: {
    title: "About us!",
    paragraph_1: "We are a team of human beings who have focused on acquiring, incorporating and putting into service, day by day, skills and competences in the technology area, as front developer, back developer, software analysts, scrum master, among others.",
    paragraph_2: "Thus, uniting everything with the modeling of soft skills, such as coaching, neurolinguistic programming, leadership neurosciences, emotional intelligence and embodied coaching, to generate the best fusion of accompaniment for our clients."
  },
  methologies: {
    title_metodology_1: "We apply prototyping and we build",
    body_metodology_1: "Based on the previously diagrammed, we design prototypes to show a first image of what the product will be like, to go to its actual construction.",

    title_metodology_2: "We actively listen and refine reach",
    body_metodology_2: "Constant work meetings to refine the requirement, with permanent advice and consultancy.",

    tile_metodology_3: "We show and value productivity",
    body_metodology_3: "We are going to live tests, which will go into production, we value its feasibility, compliance and productivity.",
  },
  services: {
    description: "We create exclusive and flexible web services, which are tailored in the best way to your requirements, and thus we accompany you to achieve your highest result",

    title_service_1: "Web Design",
    description_1: "Assembling different technologies such as CSS, HTML, Java, JavaScript, among others, in which we are experts, we guide and accompany you to produce an excellent visual product, landing page, static pages, blog, or what you would like to build, and that represents that which you want to share with others.",

    title_service_2: "Web System",
    description_2: "A system is made up of many parts that interact with each other, so we work with the best tools on the market React, Ruby, Rails, View, among others, to make you the best proposals, making your system a complete, robust and functional entity.",

    title_service_3: "Consulting",
    description_3: "We can say that today the possibilities that exist to develop Web systems, mobile, cloud, and more are almost infinite, as well as incorporating and updating new technological tools in our business, that's why we are constantly training ourselves as experts in different areas to offer you complete and cutting-edge advice.",
  },
  testimonials: {
    testimony_2: 'We were able to matrialize our ideas at a technical level, thanks to the accompaniment and advice of the guys from Human to dev, we managed to understand and strengthen some methodological aspects for the development of our processes, being much more affective in our field.',
    testimony_1: 'We had many attempts to create our website, when we searched there were many options, getting Human To Dev was of vital importance to our company, they managed to understand what we wanted, with great patience and professionalism they advised us according to our real needs.'
  }
};

export default en;
