import React from "react";

import "./SectionContactUs.scss";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

import {
  faMapMarkedAlt,
  faPhoneAlt,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas, faMapMarkedAlt, faPhoneAlt, faEnvelope);

function SectionContactUs() {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-12 contact-form-contact-info">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12 col-lg-4">
                <p className="d-flex justify-content-center justify-md-content-end m-0">
                  <span className="ion-android-mail icon mr-5">
                    <FontAwesomeIcon icon="envelope" />
                  </span>
                  <span className="align-self-center">info@humantodev.com</span>
                </p>
              </div>
              <div className="col-sm-12 col-lg-4">
                <p className="d-flex justify-content-center justify-md-content-start m-0">
                  <span className="ion-ios-location icon mr-5">
                    <FontAwesomeIcon icon="map-marker-alt" />
                  </span>
                  <span className="align-self-center">CABA, Argentina</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SectionContactUs;
