import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Layout from "../../components/Layout/Layout";
import Home from "../../pages/Home/Home";
import NotFound from "../../pages/NotFound/NotFound";

// StytleSheets
import "../../stylesheets/fontFace.scss";
import "../../stylesheets/colors.scss";
import "../../stylesheets/global.scss";
import "./App.scss";

import "bootstrap/dist/css/bootstrap.min.css";

// Match locales with regular expression containing each locale separated by `|`
const base = '/:locale(en|es)?';

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path={base} component={Home} />
          <Route  component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
