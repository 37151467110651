import React from "react";
import I18n from "../../config/I18n"

import "./CardTestimony.scss";

function CardTestimony({testimony: { name, company, body, avatar}}) {
  return (
    <div className="item">
      <div className="block-33 h-100">
        <div className="vcard d-flex mb-3">
          <div className="image align-self-center"><img src={require(`../../images/${avatar}.jpg`)} alt="Person here" /></div>
          <div className="name-text align-self-center">
          <h2 className="heading">{name}</h2>
            <span className="meta">{company}</span>
          </div>
        </div>
        <div className="text">
          <blockquote>
            <p>
             <I18n t={`testimonials.${body}`} />
            </p>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default CardTestimony;
