import React from "react";
import ReactGA from 'react-ga';
import { createBrowserHistory } from "history";

import Section from "../../components/Section/Section";
import SectionWhatDoWeDo from "../../components/SectionServices/SectionServices";
import SectionMethodology from "../../components/SectionMethodology/SectionMethodology";
import SectionTestimonials from "../../components/SectionTestimonials/SectionTestimonials";
import SectionAboutUs from "../../components/SectionAboutUs/SectionAboutUs";
import SectionContactUs from "../../components/SectionContactUs/SectionContactUs"
import SectionBanner from "../../components/SectionBanner/SectionBanner";
import "./Home.scss";

ReactGA.initialize('UA-161771984-1');
const history = createBrowserHistory();

function Home() {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname)
  });
  return (
    <React.Fragment>
      <SectionBanner />

      <Section title="service" bgLight={true}>
        <SectionWhatDoWeDo />
      </Section>

      <Section title="methology" bgLight={false}>
        <SectionMethodology />
      </Section>

      <Section title="testimony" bgLight={true}>
        <SectionTestimonials />
      </Section>

      <Section title="" bgLight={false}>
        <SectionAboutUs />
      </Section>

      <Section title="contact" bgLight={true}>
        <SectionContactUs />
      </Section>
    </React.Fragment>
  );
}

export default Home;
