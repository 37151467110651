import React from "react";

import "./NotFound.scss";

function NotFound() {
  return (
    <React.Fragment>
      <h1>404: Not Found</h1>
    </React.Fragment>
  );
}

export default NotFound;
