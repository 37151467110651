import React from "react";

import CardMethodology from '../CardMethodology/CardMethodology'
import dataMethodology from './methodologies'

import "./SectionMethodology.scss";

function SectionMethodology() {
  const methodologies = dataMethodology.methologies;
  return (
    <React.Fragment>
      <div className="container">
        <div className="row mb-5 no-gutters">
          {methodologies.map((element, index) => (<CardMethodology methodology={element} key={index} />))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SectionMethodology;
