import { createI18n } from 'react-router-i18n';
import en from "../locale/en"
import es from '../locale/es'

// Array of supported locales
// The first in the array is treated as the default locale
const locales = ['en', 'es'];

// Dictionary of translations
const translations = {
  en,
  es
}

const I18n = createI18n(
  locales,
  translations,
);

export default I18n;
