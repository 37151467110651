import React from "react";
import I18n from "../../config/I18n";

import about_1 from "../../images/about_1.jpg";
import about_2 from "../../images/about_2.jpg";

import "./SectionAboutUs.scss";

function SectionAboutUs(props) {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ml-auto pl-lg-4 mb-5 order-2">
            <img src={about_2} alt="About HTD" className="img-fluid mb-5" />
            <h1 className="mb-3 section-title">
              <I18n t="about_us.title" />
            </h1>
            <p>
              <I18n t="about_us.paragraph_1" />
            </p>
            <p className="mb-5">
              <I18n t="about_us.paragraph_2" />
            </p>
          </div>
          <div className="col-lg-6 order-1">
            <figure className="img-dotted-bg">
              <img src={about_1} alt="About HTD" className="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SectionAboutUs;
