const es = {
  language: 'Idioma: ',
  spanish: 'Español',
  english: 'Inglés',
  sections: {
    banner: 'Somos HumanToDev',
    banner_sub: 'Te acompañamos a exponenciar tecnológicamente tu idea... tu negocio.',
    methology: 'Nuestra Metodología',
    service: '¿Qué hacemos para ti?',
    testimony: '¿Qué dicen nuestros clientes?',
    contact: 'Contáctanos'
  },
  about_us: {
    title: "¡Acerca de HumanToDev Factory!",
    paragraph_1: "Somos un equipo de seres humanos que se han enfocado en adquirir, incorporar y poner al servicio, día a día, habilidades y competencias en el área tecnología, como desarrollador front, desarrollador back, analistas de software, scrum master, entre otros.",
    paragraph_2: "Así, uniendo todo con el modelado de habilidades blandas, como coaching, programación neurolinguistica, neurociencias del liderazgo, inteligencia emocional y embodied coaching, para generar la mejor fusión de acompañamiento a nuestros clientes."
  },
  methologies: {
    title_metodology_1: "Aplicamos prototipado y construimos",
    body_metodology_1: "Con base a lo anteriormente diagramado, diseñamos prototipos para mostrar una primera imagen de cómo será el producto, para ir a su construcción real.",

    title_metodology_2: "Escuchamos activamente y Refinamos alcance",
    body_metodology_2: "Reuniones constantes de trabajo para ir afinando el requerimiento, con asesoría y consultoría permanentemente.",

    tile_metodology_3: "Mostramos y valoramos productividad",
    body_metodology_3: "Vamos a pruebas en vivo, lo que saldrá a producción, valoramos su viabilidad, conformidad y productividad.",
  },
  services: {
    description: "Creamos servicios exclusivos y flexibles que se adaptan de la mejor forma para acompañarte y aesorarte en función de conseguir tu mejor resultado",

    title_service_1: "Diseños Web",
    description_1: "Ensamblando diferentes tecnologías como CSS, HTML, Java, JavaScript, entre otras, en las cuales somos expertos, te guiamos y acompañamos para producir un excelente producto visual, landing page, páginas estáticas, blog, o lo que te gustaría construir, y que represente eso que quieres compartir con otros.",

    title_service_2: "Sistemas Web",
    description_2: "Un sistema está compuesto por muchas partes que interactúan entre sí, por lo que trabajamos con las mejores herramientas del mercado React, Ruby, Rails, View, entre otras, para hacerte las mejores propuestas, haciendo de tu sistema una entidad completa, robusta y funcional.",

    title_service_3: "Consultoría",
    description_3: "Podemos decir que hoy en día se hacen casi infinitas las posibilidades que existen para desarrollar sistemas Web, mobile, cloud, y más, así como incorporar y actualizar nuevas herramientas tecnológicas en nuestro negocio, por eso constantemente nos estamos capacitando como expertos en diferentes áreas para ofrecerte una asesoría completa y de vanguardia.",
  },
  testimonials: {
    testimony_2: 'Pudimos materializar nuestras ideas a nivel técnico, gracias al acompañamiento y asesoramiento de los chicos de HumanToDev, logramos entender y fortalecer algunas aspecto metodológicos para el desarrollo de nuestros procesos, siendo muchos mas afectivos en nuestro ámbito',
    testimony_1: 'Tuvimos muchos intentos para crear nuestra web site, cuando buscamos había muchísimas opciones, conseguir a Human To Dev fue de vital importancia para nuestra compañía, lograron entender lo que queríamos, con mucha paciencia y profesionalismo nos asesoraron de acuerdo a nuestras necesidades reales.'
  }
};

export default es;
