import React from "react";
import I18n from '../../config/I18n'

import "./SectionServices.scss";
import dataService from "./services";

import CardService from "../CardService/CardService";

// import I18n from '../../config/I18n';

function SectionWhatDoWeDo() {
  const services = dataService.services;

  return (
    <React.Fragment>
      <div className="container text-center">
        <p className="mb-5">
          <I18n t='services.description' />
        </p>
        <div className="row">
          {services.map((service, index) => {
            return (
              <div className="col-lg-4 mb-4" key={index}>
                <CardService service={service} />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SectionWhatDoWeDo;
