import React from "react";

import I18n from "../../config/I18n";

import "./Section.scss";

function Section({ bgLight, title, children }) {
  const bgLightClass = bgLight ? "bg-light" : "";
  const titleRow = title ? (
    <div className="row justify-content-center">
      <h2 className="mb-5 px-4 text-center section-title">
        <I18n t={`sections.${title}`} />
      </h2>
    </div>
  ) : (
    ""
  );

  return (
    <React.Fragment>
      <section className={`section ${bgLightClass}`}>
        <div className="container">
          {titleRow}
          <div className="row">{children}</div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Section;
