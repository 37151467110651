import React from "react";

import SwitchLanguage from "../SwitchLanguage/SwitchLanguage"
import I18n from "../../config/I18n";

import "./SectionBanner.scss";

function SectionBanner() {
  return (
    <React.Fragment>
      <div className="slider-item bg-light">
        <div className="mr-5">
          <SwitchLanguage/>
        </div>
        <div className="container">
          <div className="row slider-text align-items-center justify-content-center py-5">
            <div className="col-lg-12 col-sm-12 text-center">
              <img src={require("../../images/logo-claro.png")} alt="" />
            </div>
            <div className="col-lg-12 text-center col-sm-12">
              <h1>
                <I18n t="sections.banner" />
              </h1>
              <span className="banner__sub-text--font-size">
                <I18n t="sections.banner_sub" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SectionBanner;
